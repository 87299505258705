<template>
  <v-container fluid>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-toolbar flat color="white" class="mb-5 px-5">
            <v-toolbar-title>Choisir le type de profil</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-select
              v-model="selectedProfile"
              :items="profiles"
              item-text="name"
              item-value="status"
              label="Les types disponibles"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </v-toolbar>
        </v-row>
        <v-row v-if="!selectedProfile" fill-width fill-height class="px-5">
          Choississez le profile à creer
        </v-row>
        <form enctype="multipart/form-data">
          <v-row
            v-if="selectedProfile.status == 1 || selectedProfile.status == 2"
            fill-width
            fill-height
            class="px-5"
          >
            <v-col cols="12" md="6" class="p-2">
              <v-text-field
                class="mb-2"
                v-model="informations.company.name"
                label="Nom du Cabinet / Société / Entreprise *"
                required
              ></v-text-field>
              <v-text-field
                class="mb-1"
                v-model="informations.company.reg_id_number"
                label="Numéro du registre de commerce *"
                required
              ></v-text-field>
              <v-select
                v-model="informations.company.jur_form"
                :hint="`${informations.company.jur_form.name}`"
                :items="legal"
                item-text="name"
                item-value="id"
                label="Form Juridique"
                persistent-hint
                return-object
                single-line
              ></v-select>
              <v-text-field
                class="mb-1"
                v-model="informations.company.country"
                label="Pays *"
                required
              ></v-text-field>
              <v-text-field
                class="mb-1"
                v-model="informations.company.town"
                label="Ville *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="mb-1"
                v-model="informations.company.geo_address"
                label="Adresse géographique *"
                required
              ></v-text-field>
                                                <vue-tel-input-vuetify
                      v-bind="bindProps"
                      placeholder="Numéro de téléphone"
                      class="mb-1"
                      v-model="informations.company.phone"
                    ></vue-tel-input-vuetify>
              <v-text-field
                class="mb-1"
                v-model="informations.company.fax"
                label="Fax"
                required
              ></v-text-field>
              <v-text-field
                class="mb-1"
                type="email"
                v-model="informations.company.email"
                label="Adresse électronique (e-mail) du Cabinet/Société/Entreprise *"
                required
              ></v-text-field>
              <v-text-field
                class="mb-1"
                v-model="informations.company.pseudo"
                label="Identifiant (pseudo) *"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              selectedProfile.status == 3 ||
                selectedProfile.status == 4 ||
                selectedProfile.status == 5
            "
            fill-width
            fill-height
            class="px-5"
          >
            <v-col cols="12" md="6">
              <v-text-field
                class="mb-2"
                v-model="informations.individu.name"
                label="Nom de l'individu (personne morale) *"
                required
              ></v-text-field>
              <v-text-field
                class="mb-1"
                v-model="informations.individu.surname"
                label="Prénoms de l’individu (personne morale) *"
                required
              ></v-text-field>
              <v-text-field
                class="mb-1"
                v-model="informations.individu.role"
                label="Fonction *"
                required
              ></v-text-field>
              <v-text-field
                class="mb-1"
                v-model="informations.individu.country"
                label="Pays *"
                required
              ></v-text-field>
              <v-text-field
                class="mb-1"
                v-model="informations.individu.town"
                label="Ville *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="mb-1"
                v-model="informations.individu.geo_address"
                label="Adresse géographique *"
                required
              ></v-text-field>
                                  <vue-tel-input-vuetify
                      v-bind="bindProps"
                      placeholder="Numéro de téléphone"
                      class="mb-1"
                      v-model="informations.individu.phone"
                    ></vue-tel-input-vuetify>
              <v-text-field
                class="mb-1"
                v-model="informations.individu.fax"
                label="Fax"
                required
              ></v-text-field>
              <v-text-field
                class="mb-1"
                type="email"
                v-model="informations.individu.email"
                label="Adresse électronique (e-mail) *"
                required
              ></v-text-field>
              <v-text-field
                class="mb-1"
                v-model="informations.individu.pseudo"
                label="Identifiant (pseudo) *"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <div>
            <v-btn
              v-if="selectedProfile"
              class="primary ml-3"
              :loading="this.$store.getters['Auth/loadSignup']"
              @click="addUser"
              >Creer</v-btn
            >
          </div>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";

export default {
  data: () => ({
    bindProps: {
      mode: "international",
      required: true,
      label: "Numéro de téléphone",
      enabledCountryCode: true,
      validCharactersOnly: true,
      enabledFlags: true,
      autocomplete: "off",
      name: "telephone",
      maxLen: 25,
      inputOptions: {
        showDialCode: true
      }
    },
    telValid: null,
    selectedProfile: { name: "Autorités Contractante", status: 1 },
    reset_email: "",
    modal_form: false,
    switch1: false,
    legal: [
      { name: "SA", id: 1 },
      { name: "SARL", id: 2 },
      { name: "AUTRES", id: 3 }
    ],
    credentials: {
      username: "",
      password: ""
    },
    informations: {
      company: {
        name: "",
        jur_form: { name: "SA", id: 1 },
        reg_id_number: "",
        country: "",
        town: "",
        geo_address: "",
        phone: null,
        fax: "",
        email: "",
        pseudo: ""
      },
      individu: {
        name: "",
        surname: "",
        role: "",
        country: "",
        town: "",
        geo_address: "",
        phone: null,
        fax: "",
        email: "",
        pseudo: ""
      },
      AutorityRegulation :{
        name: "",
        jur_form: { name: "AUTRES", id: 3 },
        reg_id_number: 0,
        country: "",
        town: "",
        geo_address: "",
        phone: null,
        fax: "",
        email: "",
        pseudo: ""
      },
      OrganeControl :{
        name: "",
        jur_form: { name: "AUTRES", id: 3 },
        reg_id_number: 0,
        country: "",
        town: "",
        geo_address: "",
        phone: null,
        fax: "",
        email: "",
        pseudo: ""
      }
    },
    tab: "login",
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    profiles: [
      { name: "Administrateur", status: 5 },
      { name: "Acheteur / Autorité Contractante", status: 1 },
      { name: "Cabinet/Bureau d’étude/Entreprise/Société", status: 2 },
      { name: "Fournisseur/ Entrepreneur/Prestataire", status: 3 },
      { name: "Membre de la Commission", status: 4 },
    ],
    checkbox: null,
  }),
  methods: {
    ...mapActions({
      logIn: "Auth/logIn",
      signIn: "Auth/signIn",
      userAdd: "Auth/userAdd",
      userAdd2: "Auth/userAdd2",
      resetEmail: "Auth/resetEmail"
    }),
    emptyFieldsCompanyForm(){
      // for compay
      this.informations.company.name = "";
      this.informations.company.phone = "";
      this.informations.company.fax = "";
      this.informations.company.email = "";
      this.informations.company.pseudo = "";
      this.informations.company.town = "";
      this.informations.company.country = "";
      this.informations.company.reg_id_number = "";
      this.informations.company.geo_address = "";
    },
    emptyFieldsIndividuForm(){
      // for individu
      this.informations.individu.name = "";
      this.informations.individu.surname = "";
      this.informations.individu.phone = "";
      this.informations.individu.role = "";
      this.informations.individu.fax = "";
      this.informations.individu.email = "";
      this.informations.individu.pseudo = "";
      this.informations.individu.town = "";
      this.informations.individu.country = "";
      this.informations.individu.address = "";
    },

    addUser() {
      const data_company = new FormData();
      const data_individu = new FormData();
      const data_regulation_authority = new FormData();
      const data_control_organe = new FormData();

      // COMPANY
      data_company.append("company_name", this.informations.company.name);
      data_company.append("company_reg_id_number", this.informations.company.reg_id_number);
      data_company.append("company_country", this.informations.company.country);
      data_company.append("company_town", this.informations.company.town);
      data_company.append("company_phone", this.informations.company.phone);
      data_company.append("company_address", this.informations.company.geo_address);
      data_company.append("company_fax", this.informations.company.fax);
      data_company.append("legal_form", this.informations.company.jur_form.id);
      data_company.append("company_email", this.informations.company.email);
      data_company.append("username", this.informations.company.pseudo);

      // INDIVIDU
      data_individu.append("firstname", this.informations.individu.name);
      data_individu.append("lastname", this.informations.individu.surname);
      data_individu.append("profession_title", this.informations.individu.role);
      data_individu.append("email", this.informations.individu.email);
      data_individu.append("username", this.informations.individu.pseudo);
      data_individu.append("phone", this.informations.individu.phone);
      data_individu.append("fax", this.informations.individu.fax);
      data_individu.append("country", this.informations.individu.country);
      data_individu.append("town", this.informations.individu.town);
      data_individu.append("address", this.informations.individu.geo_address);

      switch (this.selectedProfile.status) {
        case 1:
          data_company.append("profile_id", 3);
          this.userAdd(data_company);
          this.emptyFieldsCompanyForm();
          // AC
          break;
        case 2:
          data_company.append("profile_id", 2);
          this.userAdd(data_company);
          this.emptyFieldsCompanyForm();
          // OPERATEUR COMPANY
          break;
        case 3:
          data_individu.append("profile_id", 2);
          this.userAdd2(data_individu);
          this.emptyFieldsIndividuForm();
          // OPERATEUR INDIVIDU
          break;
        case 4:
          data_individu.append("profile_id", 4);
          this.userAdd2(data_individu);
          this.emptyFieldsIndividuForm();
          // MEMBRE COM
          break;
        case 5:
          data_individu.append("profile_id", 1);
          this.userAdd2(data_individu);
          this.emptyFieldsIndividuForm();
          // ADMIN
          break; 
        default:
      }
    },
    reset_email0() {
      this.$store.commit("Auth/SET_MAIL_DONE", true);
    },
    close_email() {
      this.$store.commit("Auth/CLOSE_MAIL", false);
    },
    reset_email1() {
      if (this.reset_email) {
        const email_to_send = new FormData();
        email_to_send.append("email", this.reset_email);
        console.log(email_to_send);
        this.resetEmail(email_to_send);
      } else {
        return Swal.fire({
          title: "Attention!",
          text: "Veuillez saisir votre adresse email !",
          icon: "error",
          confirmButtonText: "OK"
        });
      }
    },
    submit() {
      const data = new FormData();
      data.append("username", this.credentials.username);
      data.append("password", this.credentials.password);
      // console.log(data);
      this.logIn(data);
    }
  }
};
</script>
