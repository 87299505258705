<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-card-title>
            <h3>Les types de documents</h3>
          </v-card-title>
          <v-card-text>
            <v-list-item-group color="primary">
              <v-list-item
                three-line
                v-for="item in this.$store.getters['Auth/get_all_legal_form'].document_types"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
          <v-list> </v-list>
        </v-card>
        <v-card class="mx-auto mt-3">
          <v-card-title>
            <h3>Les types d'avis</h3>
          </v-card-title>
          <v-card-text>
            <v-list-item-group color="primary">
              <v-list-item
                three-line
                v-for="item in this.$store.getters['Auth/get_all_legal_form'].notice_types"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
          <v-list> </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto">
          <v-card-title>
            <h3>Les profiles</h3>
          </v-card-title>
          <v-card-text>
            <v-list-item-group color="primary">
              <v-list-item
                three-line
                v-for="item in this.$store.getters['Auth/get_all_legal_form'].profiles"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
          <v-list> </v-list>
        </v-card>
        <v-card class="mx-auto mt-3">
          <v-card-title>
            <h3>Les commissions</h3>
          </v-card-title>
          <v-card-text>
            <v-list-item-group color="primary">
              <v-list-item
                three-line
                v-for="item in this.$store.getters['Auth/get_all_legal_form'].commission_types"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
          <v-list> </v-list>
        </v-card>
        <v-card class="mx-auto mt-3">
          <v-card-title>
            <h3>Les demandes d'éclaircissement</h3>
          </v-card-title>
          <v-card-text>
            <v-list-item-group color="primary">
              <v-list-item
                three-line
                v-for="item in this.$store.getters['Auth/get_all_legal_form'].request_types"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
          <v-list> </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";

export default {
  data: () => ({
    bread: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Utilisateurs",
        disabled: false
      }
    ],
    selectedProfile: { name: "Autorités Contractante", status: 1 },
    reset_email: "",
    modal_form: false,
    switch1: false,
    legal: [
      { name: "SA", id: 1 },
      { name: "SARL", id: 2 },
      { name: "AUTRES", id: 3 }
    ],
    credentials: {
      username: "",
      password: ""
    },
    informations: {
      company: {
        name: "",
        jur_form: { name: "SA", id: 1 },
        reg_id_number: "",
        country: "",
        town: "",
        geo_address: "",
        phone: "",
        fax: "",
        email: "",
        pseudo: ""
      },
      individu: {
        name: "",
        surname: "",
        role: "",
        country: "",
        town: "",
        geo_address: "",
        phone: "",
        fax: "",
        email: "",
        pseudo: ""
      },
      AutorityRegulation :{
        name: "",
        jur_form: { name: "AUTRES", id: 3 },
        reg_id_number: null,
        country: "",
        town: "",
        geo_address: "",
        phone: null,
        fax: "",
        email: "",
        pseudo: ""
      },
      OrganeControl :{
        name: "",
        jur_form: { name: "AUTRES", id: 3 },
        reg_id_number: null,
        country: "",
        town: "",
        geo_address: "",
        phone: null,
        fax: "",
        email: "",
        pseudo: ""
      }
    },
    tab: "login",
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    profiles: [
      { name: "Administrateurs", status: 5 },
      { name: "Autorités Contractante", status: 1 },
      { name: "Cabinet / Société / Entreprise", status: 2 },
      { name: "Fournisseurs, Entrepreneurs, Prestataires", status: 3 },
      { name: "Membre Commission", status: 4 },
      { name: "Autorité de Régulation des marchés", status: 6 },
      { name: "Structure en charge du contrôle des marchés", status: 7 },
    ],
    checkbox: null
  }),
  methods: {
    ...mapActions({
      logIn: "Auth/logIn",
      signIn: "Auth/signIn",
      userAdd: "Auth/userAdd",
      userAdd2: "Auth/userAdd2",
      resetEmail: "Auth/resetEmail"
    }),
    addUser() {
      const data_company = new FormData();
      const data_individu = new FormData();
      const data_regulation_authority = new FormData();
      const data_control_organe = new FormData();

      // COMPANY
      data_company.append("company_name", this.informations.company.name);
      data_company.append("company_reg_id_number", this.informations.company.reg_id_number);
      data_company.append("company_country", this.credentials.country);
      data_company.append("company_town", this.informations.company.town);
      data_company.append("company_phone", this.informations.company.phone);
      data_company.append("company_address", this.credentials.geo_address);
      data_company.append("company_fax", this.informations.company.fax);
      data_company.append("legal_form", this.informations.company.jur_form.id);
      data_company.append("company_email", this.informations.company.email);
      data_company.append("username", this.informations.company.pseudo);

      // Regulation Authority
      data_regulation_authority.append("company_name", this.informations.AutorityRegulation.name);
      data_company.append("company_reg_id_number", null);
      data_regulation_authority.append("company_country", this.informations.AutorityRegulation.country);
      data_regulation_authority.append("company_town", this.informations.AutorityRegulation.town);
      data_regulation_authority.append("company_phone", this.informations.AutorityRegulation.phone);
      data_regulation_authority.append("company_address", this.informations.AutorityRegulation.geo_address);
      data_regulation_authority.append("company_fax", this.informations.AutorityRegulation.fax);
      data_regulation_authority.append("legal_form", this.informations.AutorityRegulation.jur_form.id);
      data_regulation_authority.append("company_email", this.informations.AutorityRegulation.email);
      data_regulation_authority.append("username", this.informations.AutorityRegulation.pseudo);

      // Contol Structure
      data_control_organe.append("company_name", this.informations.OrganeControl.name);
      data_company.append("company_reg_id_number", null);
      data_control_organe.append("company_country", this.informations.OrganeControl.country);
      data_control_organe.append("company_town", this.informations.OrganeControl.town);
      data_control_organe.append("company_phone", this.informations.OrganeControl.phone);
      data_control_organe.append("company_address", this.informations.OrganeControl.geo_address);
      data_control_organe.append("company_fax", this.informations.OrganeControl.fax);
      data_control_organe.append("legal_form", this.informations.OrganeControl.jur_form.id);
      data_control_organe.append("company_email", this.informations.OrganeControl.email);
      data_control_organe.append("username", this.informations.OrganeControl.pseudo);

      // INDIVIDU
      data_individu.append("firstname", this.informations.individu.name);
      data_individu.append("lastname", this.informations.individu.surname);
      data_individu.append("profession_title", this.informations.individu.role);
      data_individu.append("email", this.informations.individu.email);
      data_individu.append("username", this.informations.individu.pseudo);
      data_individu.append("phone", this.informations.individu.phone);
      data_individu.append("fax", this.informations.individu.fax);
      data_individu.append("country", this.informations.individu.country);
      data_individu.append("town", this.informations.individu.town);
      data_individu.append("address", this.informations.individu.address);

      switch (this.selectedProfile.status) {
        case 1:
          data_company.append("profile_id", 3);
          this.userAdd(data_company);
          // AC
          break;
        case 2:
          data_company.append("profile_id", 2);
          this.userAdd(data_company);
          // OPERATEUR COMPANY
          break;
        case 3:
          data_individu.append("profile_id", 2);
          this.userAdd2(data_individu);
          // OPERATEUR INDIVIDU
          break;
        case 4:
          data_individu.append("profile_id", 4);
          this.userAdd2(data_individu);
          // MEMBRE COM
          break;
        case 5:
          data_individu.append("profile_id", 1);
          this.userAdd2(data_individu);
          // ADMIN
          break;
          case 6:
          data_regulation_authority.append("profile_id", 5);
          this.userAdd(data_regulation_authority);
          // this.emptyFieldsAuthorityRegulationForm();
          // Regulation Authority
          break;
        case 7:
          data_control_organe.append("profile_id", 6);
          this.userAdd(data_control_organe);
          // this.emptyFieldsOrganeControlForm();
          // Control Structure
          break;  
        default:
      }
    },
    reset_email0() {
      this.$store.commit("Auth/SET_MAIL_DONE", true);
    },
    close_email() {
      this.$store.commit("Auth/CLOSE_MAIL", false);
    },
    reset_email1() {
      if (this.reset_email) {
        const email_to_send = new FormData();
        email_to_send.append("email", this.reset_email);
        console.log(email_to_send);
        this.resetEmail(email_to_send);
      } else {
        return Swal.fire({
          title: "Attention!",
          text: "Veuillez saisir votre adresse email !",
          icon: "error",
          confirmButtonText: "OK"
        });
      }
    },
    submit() {
      const data = new FormData();
      data.append("username", this.credentials.username);
      data.append("password", this.credentials.password);
      // console.log(data);
      this.logIn(data);
    }
  }
};
</script>
