<template>
  <v-container fluid>
    <v-card flat>
      <v-data-table
        :headers="headers"
        :search="search"
        @click:row="handleClick"
        no-data-text="Aucun Utilisateurs retrouvés"
        :items="this.$store.getters['Auth/get_all_legal_form'].user_list"
        class="elevation-1 mb-5 px-5 pt-1"
      >
        <template v-slot:[`item.company`]="{ item }">{{
          item.company ? "Entreprise" : "Individu"
        }}</template>
        <template v-slot:[`item.activitys`]="{ item }">
          {{ item.activitys.length > 0 ? item.activitys.map(a => a.name).toString() : "--" }}
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white" class="mb-5">
            <v-toolbar-title>Liste des utilisateurs</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Recherchez un utilisateur"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    defaultYear: "2021",
    search: "",
    publishDate: "",
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Plan de passation des marchés",
        disabled: true
      }
    ],
    dog: true,
    ppm: {
      title: "",
      description: "",
      file: []
    },
    dialog: false,
    headers: [
      {
        text: "Profil de l’utilisateur",
        shortable: true,
        value: "profile.title"
      },
      {
        text: "Nom de l’utilisateur",
        align: "start",
        filterable: true,
        value: "firstname"
      },
      {
        text: "E-mail de l’utilisateur",
        value: "email",
        sortable: false,
        filterable: false
      },

      { text: "Pays de l’utilisateur", value: "country", sortable: true },
      { text: "Secteur d’activité de l’utilisateur", value: "activitys", sortable: true },
      { text: "Type de compte", value: "company", sortable: true }
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: "",
      fat: 0,
      carbs: 0,
      protein: 0
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau PPM" : "Création du Plan de Passation des Marchés";
    }
  },
  created() {
    this.$store.dispatch("Auth/loadAllPPM", 2021);
  },
  methods: {
    ...mapActions({
      addPPM: "Auth/addPPM",
      loadAllPPM: "Auth/loadAllPPM",
      getPPM: "Auth/getPPM"
    }),

    onChange(e) {
      this.$store.dispatch("Auth/loadAllPPM", e);
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
    close_ppm() {
      this.$store.commit("Auth/CLOSE_PPM", false);
    },
    open_ppm() {
      console.log(this.$store.getters["Auth/isPpmDone"]);
      this.$store.commit("Auth/CLOSE_PPM", true);
    },
    addthisPPM() {
      const data = new FormData();
      data.append("title", this.ppm.title);
      data.append("description", this.ppm.description);
      if (this.ppm.file) {
        data.append("file", this.ppm.file[0]);
      } else {
        data.append("file", null);
      }
      // console.log(this.ppm.file);
      this.addPPM(data);
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") && this.desserts.splice(index, 1);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    handleClick(value) {
      const { id } = value;
      this.$router.push({ name: "showAllUsers", params: { id } });
      // this.highlightClickedRow(value);
      // this.viewDetails(value);
    },
    // goto(val){
    //   this.$router.push({ name: "showPpm", params: { id: val } });
    // },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>
