<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title subtitle="Subtitle">
            <h4 class="pl-4">
              {{ ThisUser.profile.title }}
            </h4>
            <v-chip tile class="primary ml-3">
              {{ ThisUser.email }}
            </v-chip>
            <v-spacer></v-spacer>
            <h4>
              Jour/date/heure de création du compte : {{ ThisUser.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <form v-if="!ThisUser.company">
              <v-row fill-width fill-height class="px-5">
                <v-col cols="12" md="6" class="p-2">
                  <v-text-field
                    class="mb-2"
                    readonly
                    v-model="ThisUser.firstname"
                    label="Nom"
                  ></v-text-field>
                  <v-text-field
                    class="mb-1"
                    readonly
                    v-model="ThisUser.lastname"
                    label="Prénom"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="mb-1"
                    readonly
                    v-model="ThisUser.profession"
                    label="Profession"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="mb-1"
                    readonly
                    v-model="ThisUser.country"
                    label="Pays"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="mb-1"
                    readonly
                    v-model="ThisUser.town"
                    label="Ville"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <vue-tel-input-vuetify
                    v-bind="bindProps"
                    placeholder="Numéro de téléphone"
                    class="mb-1"
                    readonly
                    v-model="ThisUser.phone"
                  ></vue-tel-input-vuetify>
                  <v-text-field
                    class="mb-1"
                    v-model="ThisUser.address"
                    label="Fax"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="mb-1"
                    type="email"
                    v-model="ThisUser.email"
                    label="Adresse électronique (e-mail)"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="mb-1"
                    v-model="ThisUser.username"
                    label="Identifiant (pseudo) *"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </form>
            <form v-if="ThisUser.company">
              <v-row fill-width fill-height class="px-5">
                <v-col cols="12" md="6" class="p-2">
                  <v-text-field
                    class="mb-2"
                    readonly
                    v-model="ThisUser.company.name"
                    label="Nom Compagnie"
                  ></v-text-field>
                  <v-text-field
                    class="mb-2"
                    readonly
                    v-model="ThisUser.company.registerIdNumber"
                    label="Numéro de registre de commerce"
                  ></v-text-field>
                  <v-text-field
                    class="mb-2"
                    readonly
                    v-model="ThisUser.company.country"
                    label="Pays"
                  ></v-text-field>
                  <v-text-field
                    class="mb-2"
                    readonly
                    v-model="ThisUser.company.town"
                    label="Ville"
                  ></v-text-field>
                  <v-text-field
                    class="mb-2"
                    readonly
                    v-model="ThisUser.company.address"
                    label="Adresse"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <vue-tel-input-vuetify
                    v-bind="bindProps"
                    placeholder="Numéro de téléphone"
                    class="mb-1"
                    readonly
                    v-model="ThisUser.company.phone"
                  ></vue-tel-input-vuetify>
                  <v-text-field
                    class="mb-2"
                    readonly
                    v-model="ThisUser.company.fax"
                    label="Numéro de téléphone"
                  ></v-text-field>
                  <v-text-field
                    class="mb-2"
                    readonly
                    v-model="ThisUser.email"
                    label="Email"
                  ></v-text-field>
                  <v-text-field
                    class="mb-2"
                    readonly
                    v-model="ThisUser.company.legalForm.title"
                    label="Forme Légale"
                  ></v-text-field>
                  <v-text-field class="mb-2" readonly label="Secteur d'activité"></v-text-field>
                </v-col>
              </v-row>
            </form>
          </v-card-text>
        </v-card>
        <!-- <v-card class="mt-5">
            <v-card-title>
              Autorisation pour l'accées
                          <v-spacer></v-spacer>
            <v-btn color="primary" @click="addAccessToDoc">
              Appliquer
            </v-btn>
            </v-card-title>
            <v-card-text>
                          <v-autocomplete
              v-if="this.$store.getters['Auth/getListDoc']"
              :items="this.$store.getters['Auth/getListDoc']"
              v-model="Doc_acc_id"
              item-text="object"
              item-value="id"
              outlined
              label="Choisir le document "
            ></v-autocomplete>
            </v-card-text>
          </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";
import VueDocPreview from "vue-doc-preview";

export default {
  components: { DatePicker, VueDocPreview },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    addi: {
      objet: "",
      publi_date: "",
      file: []
    },
    Doc_acc_id: 0,
    letter: {
      type: 0,
      file: []
    },
    report: {
      type: 0
    },
    ppm: [],
    additif: false
  }),
  computed: {
    ThisUser() {
      const id = this.id;
      let filtered = this.$store.getters["Auth/get_all_legal_form"].user_list.filter(function(
        value,
        index,
        arr
      ) {
        return value.id == id;
      });
      return filtered[0];
    }
  },
  created() {
    this.loadUser();
  },
  methods: {
    ...mapActions({
      extension_addD: "Auth/extension_addD",
      loadUser: "Auth/getData"
    }),
    traceability() {
      return this.$store.getters["Auth/get_selected_doc"].download_list;
    },
    addExtension() {
      const data = new FormData();
      data.append("object", this.addi.objet);
      data.append("publi_date", this.addi.publi_date);
      if (this.addi.file) {
        data.append("file", this.addi.file[0]);
      } else {
        data.append("file", null);
      }
      data.append("document", this.id);
      this.extension_addD(data);
    },
    addAccessToDoc() {
      if (this.Doc_acc_id) {
        const data = new FormData();
        data.append("doc_id", this.Doc_acc_id);
        data.append("user_id", this.ThisUser.id);
        this.$store.dispatch("Auth/ActiveDocToUser", data);
      }
    },
    addLetter() {
      const data = new FormData();
      data.append("doc_id", this.id);
      data.append("letter_type_id", this.letter.type);
      if (this.addi.file) {
        data.append("file", this.letter.file[0]);
      } else {
        data.append("file", null);
      }
      this.$store.dispatch("Auth/addLetter_doc", data);
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
